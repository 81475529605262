import React, { useState } from "react";
import { Col, Drawer, Row } from "antd";
import strategy_advisor_1_about_img from "../../assets/img/team/DukePhanTeam.webp";
import technical_advisor_1_about_img from "../../assets/img/team/De_Deo.webp";
import foreign_affairs_director_1_about_img from "../../assets/img/team/Vu_Tran.webp";
import outsourcing_coordinator_1_about_img from "../../assets/img/team/Igor_Palibrk.png";
import ceo_about_img from "../../assets/img/team/TruongQuocTuanTeam.webp";

import project_manager_1_about_img from "../../assets/img/team/DuyPhanTeam.webp";
import technical_leader_1_about_img from "../../assets/img/team/BinhCaoTeam.webp";
import human_resource_1_about_img from "../../assets/img/team/NiNgoTeam.webp";

import i18n from "../../lib/Language";
import { CloseOutlined } from "@ant-design/icons";

const ManagementTeam = () => {
  const teamLevelAdvisor = [
    {
      name: "DUKE PHAN",
      imgSrc: strategy_advisor_1_about_img,
      shortDesc: i18n.t("aboutUs.managementTeam.positionStrategyAdvisor"),
      desc: i18n.t("aboutUs.managementTeam.strategyBio"),
    },

    {
      name: "DE DEO",
      imgSrc: technical_advisor_1_about_img,
      shortDesc: i18n.t("aboutUs.managementTeam.positionTechnicalAdvisor"),
      desc: i18n.t("aboutUs.managementTeam.technicalBio"),
    },
  ];
  const teamLevelBOD = [
    {
      name: "TUAN TRUONG",
      imgSrc: ceo_about_img,
      shortDesc: i18n.t("aboutUs.managementTeam.positionCEO"),
      desc: i18n.t("aboutUs.managementTeam.CEOBio"),
    },
  ];
  const teamSales = [
    {
      name: "VU TRAN",
      imgSrc: foreign_affairs_director_1_about_img,
      shortDesc: i18n.t("aboutUs.managementTeam.salesDirector"),
      desc: i18n.t("aboutUs.managementTeam.salesDirectorBio"),
    },
    {
      name: "IGOR PALIBRK",
      imgSrc: outsourcing_coordinator_1_about_img,
      shortDesc: i18n.t("aboutUs.managementTeam.outsourcingCoordinator"),
      desc: i18n.t("aboutUs.managementTeam.outsourcingCoordinatorBio"),
    },
  ];
  const teamLeader = [
    {
      name: "DUNG PHAN",
      imgSrc: project_manager_1_about_img,
      shortDesc: i18n.t("aboutUs.managementTeam.positionManager"),
      desc: i18n.t("aboutUs.managementTeam.pmBio_1"),
    },
    {
      name: "BINH CAO",
      imgSrc: technical_leader_1_about_img,
      shortDesc: i18n.t("aboutUs.managementTeam.positionLeader"),
      desc: i18n.t("aboutUs.managementTeam.leaderBio_1"),
    },
    {
      name: "NI NGO",
      imgSrc: human_resource_1_about_img,
      shortDesc: i18n.t("aboutUs.managementTeam.positionOperationManager"),
      desc: i18n.t("aboutUs.managementTeam.opManagerBio"),
    },
  ];

  const [nameMember, setNameMember] = useState();
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setNameMember("");
    setOpen(false);
  };

  const componentInforMember = (members) => {
    return members.map((member, index) => {
      const arrDesc = member.desc.split("\n");
      return (
        <Col xs={24} lg={8}>
          <section className="member_container">
            <div className="border_member">
              <img src={member.imgSrc} alt="" />
            </div>
            <span
              className="member_name"
              onClick={() => {
                setNameMember(member.name);
                showDrawer(true);
              }}
            >
              {member.name}
            </span>
            <p>{member.shortDesc}</p>
            {nameMember === member.name && (
              <Drawer
                title={member.name}
                placement="bottom"
                onClose={onClose}
                open={open}
                getContainer={false}
              >
                {arrDesc && arrDesc.map((item) => <p>{item}</p>)}
              </Drawer>
            )}
          </section>
        </Col>
      );
    });
  };

  return (
    <div className="management_team">
      <h1 className="fz-40 color-red">
        {i18n.t("aboutUs.managementTeam.managementTeamTitle")}
      </h1>

      <Row justify="center" gutter={[30, 30]} style={{ marginTop: "30px" }}>
        {componentInforMember(teamLevelAdvisor)}
      </Row>
      <Row justify="center" gutter={[30, 30]} style={{ marginTop: "30px" }}>
        {componentInforMember(teamLevelBOD)}
      </Row>
      <Row justify="center" gutter={[30, 30]} style={{ marginTop: "30px" }}>
        {componentInforMember(teamSales)}
      </Row>
      <Row justify="center" gutter={[30, 30]} style={{ marginTop: "30px" }}>
        {componentInforMember(teamLeader)}
      </Row>
    </div>
  );
};

export default ManagementTeam;
