import React from "react";
import NS_team from "../../assets/img/team/NS_team.webp";
import { Col, Image, Row } from "antd";
import i18n from "../../lib/Language";
const Overview = () => {
  return (
    <div className="overview_container">
      <Row gutter={[60, 30]} align="middle">
        <Col xs={24} lg={12}>
          <Image src={NS_team} alt="" />
        </Col>
        <Col xs={24} lg={12}>
          <p>
            <span>Nova Square </span>
            {i18n.t("contact.contactDesc")}
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default Overview;
